import size from 'lodash/size';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import { Button, Pagination } from '@demandscience/ui';
import DataGridHeader from './DataGridHeader';
import useSelection from './Selection/useSelection';
import useSearch from 'components/Search/useSearch';
import DetailSidebar from 'components/DetailSidebar';
import useFilters from 'components/Filters/useFilters';
import {
  KlarityFeature,
  Model,
  ModelId,
  ModelType,
  RowLayout,
  SearchResponse,
  ViewLayout,
} from 'types';
import CornerUpLeftIcon from '@demandscience/ui/icons/corner-up-left';
import IllustrationEmpty from 'components/Illustrations/IllustrationEmpty';
import DataGridListLayout from './DataGridListLayout';
import useCustomization from 'components/SearchCustomization/useCustomization';
import DataGridTableLayout from './DataGridTableLayout';
import DataGridSubheader from './DataGridSubheader';
import axios from 'axios';
import InExListsFilterErrors from 'components/Filters/InExListsFilterErrors';
import PageSelector from './Pagination/PageSelection';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

export type DataGridProps = {
  id?: string;
  kind: ModelType;
  rowLayout?: RowLayout;
};

const DataGridComponent = ({ kind, rowLayout = RowLayout.Narrow }: DataGridProps) => {
  const containerRef = useRef<HTMLElement>(null);
  const {
    query: search,
    page,
    pageSize,
    setPage,
    sort,
    setSort,
    maxContactsPerCompany,
    excludeList,
  } = useSearch(kind);
  const { selection, selectionDisabled, batchSelection, add, remove, viewSelected, isSelected } =
    useSelection();
  const {
    layout,
    filters: { dense },
  } = useCustomization();

  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  const { data = {}, isLoading, error, isError, isPreviousData } = search;
  const { hits: currentRows = [], pagination: { count } = { count: 0 } } = data as SearchResponse;
  const rows = viewSelected ? selection : currentRows;
  const currentSelected = filter(rows, isSelected);
  const loading = isLoading || isPreviousData;
  const errorMessage =
    isError &&
    (axios.isAxiosError(error) && (error.response?.data as any)?.error_code === 'inaccessible_lists'
      ? 'Sorry, this search uses a list that is either not visible to you or has been deleted.'
      : 'Unable to load the records, please try again later.');

  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const totalPage = Math.ceil((count < 10000 ? count : 10000) / pageSize);

  const selectedRow = selectedRowIndex > -1 ? rows[selectedRowIndex] : undefined;
  const constraints = useMemo(
    () => (maxContactsPerCompany ? { max_contacts_per_company: maxContactsPerCompany } : undefined),
    [maxContactsPerCompany],
  );

  const { hasUndo, undoFilter, filters } = useFilters();

  const handleRowSelect = useCallback(
    (row: Model) => (selected: boolean) => {
      if (selected) {
        if (batchSelection) {
          remove(row);
        } else {
          add(row);
        }
      } else {
        if (batchSelection) {
          add(row);
        } else {
          remove(row);
        }
      }
    },
    [add, batchSelection, remove],
  );

  const handleSelectCurrentRows = useCallback(() => {
    if (batchSelection) {
      remove(currentRows);
    } else {
      add(currentRows);
    }
  }, [add, batchSelection, currentRows, remove]);

  const handleClearCurrentRows = useCallback(() => {
    if (batchSelection) {
      add(currentRows);
    } else {
      remove(currentRows);
    }
  }, [add, batchSelection, currentRows, remove]);

  const handleCheckboxChange = (selected: boolean) => {
    if (selected) {
      handleSelectCurrentRows();
    } else {
      handleClearCurrentRows();
    }
  };

  const handlePrev = () => {
    if (selectedRowIndex > 0) {
      setSelectedRowIndex(selectedRowIndex - 1);
    }
  };

  const handleNext = () => {
    if (selectedRowIndex < size(currentRows) - 1) {
      setSelectedRowIndex(selectedRowIndex + 1);
    }
  };

  const handlePageChange = useCallback(
    (_: any, page: number) => {
      setPage(page - 1);
    },
    [setPage],
  );

  const handleDetailsShow = useCallback(
    (row: Model) => () => {
      sessionStorage.setItem('filter', JSON.stringify(filters));
      const index = findIndex(rows, { dsid: row.dsid });

      setSelectedRowIndex(index);
    },
    [filters, rows],
  );

  const handleDetailsClose = useCallback(() => {
    setSelectedRowIndex(-1);
  }, []);

  const isSelectedRow = useCallback(
    (row: ModelId, index: number) => isSelected(row, page * pageSize + index),
    [isSelected, page, pageSize],
  );

  const handleDetailsSelect = useCallback(
    (checked: boolean) => {
      if (checked && selectedRow) {
        if (batchSelection) {
          remove(selectedRow);
        } else {
          add(selectedRow);
        }
      } else {
        if (!selectedRow || !selectedRow.dsid) {
          return;
        }
        if (batchSelection) {
          add(selectedRow);
        } else {
          remove(selectedRow);
        }
      }
    },
    [add, batchSelection, remove, selectedRow],
  );
  const emptySearchResults = () => {
    return (
      <div className="flex flex-col items-center mt-36">
        <IllustrationEmpty className="w-40 h-40" />
        {excludeList ? (
          <p className="sm:w-52 text-sm font-medium text-center mt-6">
            All {kind === 'company' ? 'companies' : `${kind}s`} in this search have been purchased
          </p>
        ) : (
          <p className="sm:w-52 text-sm font-medium text-center mt-6">
            Sorry, no matching records found for your query
          </p>
        )}
        {hasUndo && (
          <div className="flex items-center mt-3">
            {excludeList ? (
              <p className="text-sm text-gray-500">Add all or edit your last filter using Undo</p>
            ) : (
              <p className="text-sm text-gray-500">Try reverting your last filter using Undo</p>
            )}
            <Button
              className="flex-shrink-0 px-1 py-1 border-none group text-blue-500 hover:bg-primary-50 disabled:text-gray-300 disabled:hover:bg-transparent focus:ring-0 ml-2"
              borderless
              onClick={undoFilter}
              disabled={!hasUndo}
              aria-label="undo filter"
            >
              <CornerUpLeftIcon className="w-5 h-5" />
            </Button>
          </div>
        )}
      </div>
    );
  };
  const paginationFooter = currentRows?.length > 0 && (
    <div className="sticky left-0 flex justify-end pt-4 pb-8 mr-4">
      <Pagination.Combined
        size="sm"
        // Vault failure: Cannot use page number to display more than 10,000 records.
        count={count < 10000 ? count : 10000}
        totalCount={count}
        page={page ? page + 1 : 1}
        rowsPerPage={25}
        onPageChange={handlePageChange}
      />

      <PageSelector
        totalPages={totalPage}
        currentPage={page ? page + 1 : 1} // Adjusting for one-based index
        onPageChange={(selectedPage) => setPage(selectedPage - 1)} // Adjusting back to zero-based index
        origin="top-left"
      />
    </div>
  );

  useEffect(() => {
    // Scroll to top when user change page during pagination
    if (page > -1 && containerRef?.current)
      containerRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
  }, [page, containerRef]);

  const isOpen = isContactDisabled && kind === 'contact' ? false : Boolean(selectedRow);
  return (
    <div className="flex flex-col isolate h-full">
      {isError && (
        <div className="flex flex-col items-center mt-36">
          <IllustrationEmpty className="w-40 h-40" />
          <p className="sm:w-80 text-sm font-medium text-center mt-6">{errorMessage}</p>
        </div>
      )}
      {!isError && (
        <>
          {(loading || (!loading && !isEmpty(rows))) && (
            <DataGridHeader
              kind={kind}
              selectCurrentPage={handleSelectCurrentRows}
              clearCurrentPage={handleClearCurrentRows}
              sort={sort}
              setSort={setSort}
              setPage={setPage}
              loading={loading}
              checkboxProps={{
                checked: !isEmpty(currentSelected),
                onChange: handleCheckboxChange,
                checkType: size(currentSelected) === size(rows) ? 'check' : 'minus',
                disabled: selectionDisabled,
              }}
              paginationProps={{
                page,
                pageSize,
                setPage,
                count,
                totalPage,
              }}
              constraints={constraints}
              isSearch
            />
          )}
          {kind === ModelType.Contact && <DataGridSubheader />}
          {!loading && isEmpty(rows) && emptySearchResults()}
          {layout.view === ViewLayout.List && (
            <DataGridListLayout
              ref={containerRef as React.Ref<HTMLUListElement>}
              kind={kind}
              rows={rows}
              isSelected={isSelectedRow}
              selectionDisabled={selectionDisabled}
              selectedRow={selectedRow}
              handleRowSelect={handleRowSelect}
              handleDetailsShow={handleDetailsShow}
              rowLayout={rowLayout}
              footer={paginationFooter}
              dense={dense}
            />
          )}
          {layout.view === ViewLayout.Table && (
            <DataGridTableLayout
              ref={containerRef as React.Ref<HTMLDivElement>}
              className="overscroll-none"
              kind={kind}
              rows={rows}
              isSelected={isSelectedRow}
              selectionDisabled={selectionDisabled}
              selectedRow={selectedRow}
              handleRowSelect={handleRowSelect}
              handleDetailsShow={handleDetailsShow}
              footer={paginationFooter}
              dense={dense}
            />
          )}
        </>
      )}
      <DetailSidebar
        kind={kind}
        open={isOpen}
        row={selectedRow}
        onClose={handleDetailsClose}
        selectionDisabled={selectionDisabled}
        selected={selectedRow ? isSelectedRow(selectedRow, selectedRowIndex) : false}
        onSelectChange={handleDetailsSelect}
        onPrev={selectedRowIndex > 0 ? handlePrev : undefined}
        onNext={selectedRowIndex < size(rows) - 1 ? handleNext : undefined}
      />
      <InExListsFilterErrors
        ids={
          axios.isAxiosError(error) && (error?.response?.data as any)?.meta_data?.inaccessible_lists
        }
      />
    </div>
  );
};

export default DataGridComponent;
