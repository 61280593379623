import Home from './Home';
import Search from './Search';
import Settings from './Settings';
import Manage from './Manage';
import PlanAndUsage from './Manage/PlanAndUsage';
import ExclusionLists from './Manage/ExclusionLists';
import TeamMember from './Manage/Team/Member';
import Team from './Manage/Team';
import Bounces from './Manage/Bounces';
import Exports from './Manage/Exports';
import Credits from './Manage/Credits';
import Billing from './Manage/Billing';
import NotFound from 'pages/NotFound';
import MainLayout from 'layouts/Main';
import TrackRoute from 'components/TrackRoute';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Lists from './Lists';
import ListDetails from './Lists/Details';
import RequireAuthRole from 'components/Auth/RequireAuthRole';
import { Role } from 'types';
import NotificationsProvider from 'components/Notifications/NotificationsProvider';
import SupportRequestProvider from 'components/SupportRequest/SupportRequestProvider';
import SettingsSalesforce from './Settings/Integrations/SettingsSalesforce';
import SettingsMarketo from './Settings/Integrations/SettingsMarketo';
import SettingsHubspot from './Settings/Integrations/SettingsHubspot';
import SettingsProfile from './Settings/Profile';
import SettingsPassword from './Settings/Password';
import SettingsIntegrations from './Settings/Integrations';
import SettingsPreviousExports from './Settings/PreviousExports';
import Message from './Buy/Message';
import Plans from 'components/Plans/Plans';
import BookMarkHome from 'components/BookMark/ViewBookMark/BookMarkHome';
import OpenBookmark from 'components/BookMark/OpenBookmark/OpenBookmark';
import FeatureFlagProvider from 'components/FeatureFlag/FeatureFlagProvider';
import useOrganization from 'components/Organization/useOrganization';
import { Banner } from 'components/Banner/Banner';

const DashboardRoutes = () => {
  const location = useLocation();
  const showBuyCredits = location.state?.showBuyCredits;
  const { data: organization } = useOrganization();
  const vaultLoading = organization?.list_write_disabled;

  return (
    <SupportRequestProvider showBuyCredits={showBuyCredits}>
      <NotificationsProvider>
        {vaultLoading && <Banner />}

        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route
              index
              element={
                <TrackRoute title="Home">
                  <FeatureFlagProvider>
                    <Home />
                  </FeatureFlagProvider>
                </TrackRoute>
              }
            />
            <Route
              path="/search"
              element={
                <TrackRoute title="Search">
                  <Search />
                </TrackRoute>
              }
            >
              <Route
                path="bookmark"
                element={
                  <TrackRoute title="Bookmark">
                    <BookMarkHome />
                  </TrackRoute>
                }
              />
              <Route
                path="bookmarkModule"
                element={
                  <TrackRoute title="Open Bookmark">
                    <OpenBookmark />
                  </TrackRoute>
                }
              />
            </Route>
            <Route
              path="lists"
              element={
                <TrackRoute title="Lists">
                  <Lists />
                </TrackRoute>
              }
            />
            <Route
              path="lists/:id"
              element={
                <TrackRoute title="List detail">
                  <ListDetails />
                </TrackRoute>
              }
            />
            <Route
              path="manage/*"
              element={
                <RequireAuthRole
                  role={Role.Manager}
                  renderNoRole={() => <NotFound className="mt-8 px-6" />}
                >
                  <FeatureFlagProvider>
                    <Manage />
                  </FeatureFlagProvider>
                </RequireAuthRole>
              }
            >
              <Route index element={<Navigate replace to="plan" />} />
              <Route
                path="plan"
                element={
                  <TrackRoute title="Plan & usage - Manager dashboard">
                    <PlanAndUsage />
                  </TrackRoute>
                }
              />
              <Route
                path="team/*"
                element={
                  <TrackRoute title="Team - Manager dashboard">
                    <Team />
                  </TrackRoute>
                }
              >
                <Route path=":id" element={<TeamMember />} />
              </Route>
              <Route
                path="exclusion-lists"
                element={<Navigate to="/manage/suppression-lists" replace />}
              />
              <Route
                path="suppression-lists"
                element={
                  <TrackRoute title="Suppression lists - Manager dashboard">
                    <ExclusionLists />
                  </TrackRoute>
                }
              />
              <Route
                path="bounces"
                element={
                  <TrackRoute title="Bounces - Manager dashboard">
                    <Bounces />
                  </TrackRoute>
                }
              />
              <Route
                path="exports"
                element={
                  <TrackRoute title="Exports - Manager dashboard">
                    <Exports />
                  </TrackRoute>
                }
              />
              <Route
                path="credits"
                element={
                  <TrackRoute title="Credits - Manager dashboard">
                    <Credits />
                  </TrackRoute>
                }
              />
              <Route
                path="billing"
                element={
                  <TrackRoute title="Billing - Manager dashboard">
                    <Billing />
                  </TrackRoute>
                }
              />
            </Route>
            <Route
              path="buy/*"
              element={
                <RequireAuthRole
                  role={Role.Manager}
                  allowedAccount={(user) => !user.organization.selfservice_subscription_disabled}
                  renderNoRole={() => <Navigate to="/" state={{ showBuyCredits: true }} />}
                />
              }
            >
              <Route
                index
                element={
                  <TrackRoute title="Plans - Buy">
                    <Plans />
                  </TrackRoute>
                }
              />
              <Route
                path="message"
                element={
                  <TrackRoute title="Message - Buy">
                    <Message />
                  </TrackRoute>
                }
              />
            </Route>
            <Route
              path="settings/integrations/salesforce"
              element={
                <TrackRoute title="Salesforce integration - Settings">
                  <SettingsSalesforce />
                </TrackRoute>
              }
            />
            <Route
              path="settings/integrations/marketo"
              element={
                <TrackRoute title="Marketo integration - Settings">
                  <SettingsMarketo />
                </TrackRoute>
              }
            />
            <Route
              path="settings/integrations/hubspot"
              element={
                <TrackRoute title="Hubspot integrations - Settings">
                  <SettingsHubspot />
                </TrackRoute>
              }
            />
            <Route path="settings/*" element={<Settings />}>
              <Route index element={<Navigate replace to="profile" />} />
              <Route
                path="profile"
                element={
                  <TrackRoute title="Profile - Settings">
                    <SettingsProfile />
                  </TrackRoute>
                }
              />
              <Route
                path="security"
                element={
                  <TrackRoute title="Security & Login - Settings">
                    <SettingsPassword />
                  </TrackRoute>
                }
              />
              <Route
                path="integrations"
                element={
                  <TrackRoute title="Integrations - Settings">
                    <SettingsIntegrations />
                  </TrackRoute>
                }
              />
              <Route
                path="previous-exports"
                element={
                  <TrackRoute title="Previous exports - Settings">
                    <SettingsPreviousExports />
                  </TrackRoute>
                }
              />
              <Route path="*" element={<NotFound className="mt-8" />} />
            </Route>
            <Route
              path="buyer-intent"
              element={
                <TrackRoute title="Buyer-Intent">
                  <div>Under Development</div>
                </TrackRoute>
              }
            />
            <Route path="*" element={<NotFound className="mt-8" />} />
          </Route>
        </Routes>
      </NotificationsProvider>
    </SupportRequestProvider>
  );
};

export default DashboardRoutes;
