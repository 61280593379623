import { useState } from 'react';
import { Clock, Search } from '@demandscience/ui/icons';
import FilterInfo from 'components/Filter/FilterInfo';

interface BookmarkInfoProps extends React.HTMLAttributes<HTMLDivElement> {
  bookmark: any;
  isOpen: boolean;
}

const BookmarkInfo = ({ isOpen, bookmark, ...props }: BookmarkInfoProps) => {
  const [data] = useState(bookmark);

  if (!data) return <div>Loading...</div>;

  const formatDate = (dateString: string) =>
    new Date(dateString)
      .toLocaleDateString('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(',', '');

  const earliestDate = formatDate(data?.created_at);
  let latestDate: string | undefined;

  if (data?.updated_at?.length) {
    latestDate = formatDate(data.updated_at[data.updated_at.length - 1]);
  }

  const filterKeys = Object.keys(data?.filters.filters);

  return (
    <div {...props} className="relative z-99">
      {isOpen && (
        <div className="custom-box">
          <div className="flex justify-between items-center mb-2">
            <span className="flex items-center w-56 h-5">
              <Clock className="h-5 w-5 mr-5" style={{ color: '#64748B' }} />
              {data?.updated_at?.length || 0} Updates
              <span style={{ color: '#64748B' }}>(auto)</span>
            </span>
          </div>

          <div className="mb-4 ml-12">
            <div className="text-sm text-gray-500">{earliestDate} (created)</div>
            {latestDate && <div className="text-sm text-gray-500">{latestDate}(last update)</div>}
          </div>

          <div className="flex justify-between items-center mb-2">
            <span className="flex items-center w-56 h-5">
              <Search className="h-5 w-5 mr-5" style={{ color: '#64748B' }} />
              {filterKeys?.length} Filters
            </span>
          </div>

          <div className="w-full max-h-[10rem] overflow-y-auto">
            <FilterInfo data={data?.filters.filters} />
          </div>
        </div>
      )}
    </div>
  );
};

export default BookmarkInfo;
