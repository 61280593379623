import classNames from 'classnames';
import { Progress } from '@demandscience/ui';

import { BulkCredits } from 'types';

type CreditsUsageProps = {
  bulkCredits: BulkCredits;
};

const CreditsUsage = ({ bulkCredits }: CreditsUsageProps) => {
  const { quota, current_period_used, available } = bulkCredits;
  const lowOnCredits = available < 10;
  const percentUsed = (current_period_used / quota) * 100;

  return (
    <div className="space-y-1">
      <p className="text-[10px] uppercase font-semibold">Credits</p>
      <Progress value={percentUsed} size="sm" theme={lowOnCredits ? 'error' : 'primary'} />
      <p className="flex items-center justify-between">
        <span
          className={classNames('text-xs', {
            'text-gray-500': !lowOnCredits,
            'text-error-500': lowOnCredits,
          })}
        >
          {current_period_used.toLocaleString()} used
        </span>
        <span className="text-xs text-gray-500" data-testid="credits-text">
          {quota === -1 ? 'Unlimited' : `${quota.toLocaleString()} total`}
        </span>
      </p>
    </div>
  );
};

export default CreditsUsage;
