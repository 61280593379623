import React, { useEffect, useState, useMemo } from 'react';
import { Checkbox } from '@demandscience/ui';

export interface SelectedOptions {
  [industryId: string]: {
    [optionId: string]: { exclude?: boolean; selected: boolean };
  } & { exclude?: boolean };
}

interface IndustryWithTotal {
  id: string;
  label: string;
  options?: SubIndustryWithTotal[];
  total_companies?: number;
}

interface SubIndustryWithTotal {
  id: string;
  label: string;
  total_companies?: number;
}

interface IndustryListProps {
  data: IndustryWithTotal[];
  selectedOptions: SelectedOptions;
  setSelectedOptions: React.Dispatch<React.SetStateAction<SelectedOptions>>;
}

const numberFormatter = new Intl.NumberFormat('en-US');

const IndustryList: React.FC<IndustryListProps> = ({
  data,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleCheckboxChange = (industryId: string, optionId?: string) => {
    setSelectedOptions((prev) => {
      const updated = { ...prev };

      if (optionId) {
        updated[industryId] = {
          ...updated[industryId],
          [optionId]: {
            selected: !updated[industryId]?.[optionId]?.selected,
          },
        };
      } else {
        const allOptions = data.find((ind) => ind.id === industryId)?.options || [];
        const allSelected = allOptions.every((opt) => updated[industryId]?.[opt.id]?.selected);

        updated[industryId] = allOptions.reduce(
          (acc, opt) => ({
            ...acc,
            [opt.id]: { selected: !allSelected },
          }),
          {},
        );
      }

      return updated;
    });
  };

  const handleExcludeToggle = (industryId: string, optionId?: string) => {
    setSelectedOptions((prev) => {
      const updated = { ...prev };

      if (optionId) {
        updated[industryId] = {
          ...updated[industryId],
          [optionId]: {
            selected: false,
            exclude: !updated[industryId]?.[optionId]?.exclude,
          },
        };
      } else {
        const allOptions = data.find((ind) => ind.id === industryId)?.options || [];
        const excludeCategory = !updated[industryId]?.exclude;

        updated[industryId] = allOptions.reduce(
          (acc, opt) => ({
            ...acc,
            [opt.id]: { selected: false, exclude: excludeCategory },
          }),
          {},
        );

        updated[industryId].exclude = excludeCategory;
      }

      return updated;
    });
  };

  const [columnsCount, setColumnsCount] = useState(() => {
    if (typeof window === 'undefined') return 1;
    const width = window.innerWidth;
    if (width < 1024) return 1;
    if (width < 1280) return 2;
    return 3;
  });

  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth;
      let count = 1;
      if (width < 1024) count = 1;
      else if (width < 1280) count = 2;
      else count = 3;
      setColumnsCount(count);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const columnsArray = useMemo(() => {
    const industriesWithHeights = data.map((industry) => {
      const height = (industry.options?.length || 0) + 1;
      return { industry, height };
    });

    industriesWithHeights.sort((a, b) => b.height - a.height);

    const columnsArray = Array.from({ length: columnsCount }, () => [] as IndustryWithTotal[]);
    const columnHeights = new Array(columnsCount).fill(0);

    industriesWithHeights.forEach(({ industry, height }) => {
      const minHeightColumnIndex = columnHeights.indexOf(Math.min(...columnHeights));
      columnsArray[minHeightColumnIndex].push(industry);
      columnHeights[minHeightColumnIndex] += height;
    });

    return columnsArray;
  }, [data, columnsCount]);

  return (
    <div className="flex flex-wrap font-inter">
      {columnsArray.map((columnData, columnIndex) => (
        <div
          key={columnIndex}
          className={`w-full ${
            columnsCount === 2 ? 'lg:w-1/2' : columnsCount === 3 ? 'lg:w-1/2 xl:w-1/3' : ''
          } pr-4`}
        >
          {columnData.map((industry) => {
            const subCats =
              industry.options?.map((opt) => selectedOptions[industry.id]?.[opt.id]) || [];
            const anySelected = subCats.some((sc) => sc?.selected);
            const anyExcluded = subCats.some((sc) => sc?.exclude);
            const allExcluded = subCats.length > 0 && subCats.every((sc) => sc?.exclude);
            const allSelected = subCats.length > 0 && subCats.every((sc) => sc?.selected);
            const mixed = anySelected && anyExcluded;
            const noneSelectedOrExcluded = subCats.length > 0 && !anySelected && !anyExcluded;

            let theme: 'primary' | 'secondary' = 'primary';
            let checkType: 'minus' | 'check' = 'check';

            if (mixed) {
              theme = 'primary';
              checkType = 'minus';
            } else if (allExcluded) {
              theme = 'secondary';
              checkType = 'minus';
            } else if (allSelected) {
              theme = 'primary';
              checkType = 'check';
            } else if (noneSelectedOrExcluded) {
              theme = 'primary';
              checkType = 'check';
            } else {
              theme = 'primary';
              checkType = 'minus';
            }

            const isChecked = !noneSelectedOrExcluded;

            return (
              <div key={industry.id} className="mb-1">
                <div className="relative group flex items-center">
                  <Checkbox
                    id={`industry-${industry.id}`}
                    size="sm"
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(industry.id)}
                    theme={theme}
                    checkType={checkType}
                  />
                  <label
                    htmlFor={`industry-${industry.id}`}
                    className="font-medium text-xs leading-4 text-gray-500 flex items-center ml-2"
                  >
                    <span className="flex-1 overflow-hidden text-ellipsis">{industry.label}</span>
                  </label>
                  {industry.total_companies && (
                    <span className="ml-auto text-xs text-gray-500 group-hover:hidden">
                      {`[${numberFormatter.format(industry.total_companies)}]`}
                    </span>
                  )}
                  <button
                    type="button"
                    onClick={() => handleExcludeToggle(industry.id)}
                    className="text-xs text-red-500 hidden group-hover:inline-block ml-1"
                  >
                    Exclude
                  </button>
                </div>
                <div className="ml-6">
                  {industry.options?.map((option) => (
                    <div
                      key={option.id}
                      className="relative group flex items-center mb-1.5 h-[18px]"
                    >
                      <Checkbox
                        id={`${industry.id}-${option.id}`}
                        size="sm"
                        checked={
                          !!selectedOptions[industry.id]?.[option.id]?.exclude ||
                          !!selectedOptions[industry.id]?.[option.id]?.selected
                        }
                        onChange={() => handleCheckboxChange(industry.id, option.id)}
                        theme={
                          selectedOptions[industry.id]?.[option.id]?.exclude
                            ? 'secondary'
                            : 'primary'
                        }
                        checkType={
                          selectedOptions[industry.id]?.[option.id]?.exclude
                            ? 'minus'
                            : selectedOptions[industry.id]?.[option.id]?.selected
                            ? 'check'
                            : 'check'
                        }
                      />

                      <label
                        htmlFor={`${industry.id}-${option.id}`}
                        className="font-medium text-xs leading-4 text-gray-500 flex items-center ml-2"
                      >
                        <span className="flex-1 overflow-hidden text-ellipsis">{option.label}</span>
                      </label>
                      {option.total_companies && (
                        <span className="ml-auto text-[12px] text-gray-500 group-hover:hidden">
                          {`[${numberFormatter.format(option.total_companies)}]`}
                        </span>
                      )}
                      <button
                        type="button"
                        onClick={() => handleExcludeToggle(industry.id, option.id)}
                        className="text-[12px] text-red-500 hidden group-hover:inline-block ml-1"
                      >
                        Exclude
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default IndustryList;
