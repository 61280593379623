import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addComment,
  createBookMark,
  deleteBookmark,
  deleteBookmarkList,
  updateBookmark,
  downloadExportList,
} from 'api/bookmark';
import useSnackbar from 'components/Snackbar/useSnackbar';

const useMutateBookMark = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();
  const { showMessage } = useSnackbar();

  const create = useMutation(createBookMark, {
    ...options,
  });

  const deleteSingleBookmark = useMutation(deleteBookmark, {
    onSuccess: (_, _id) => {
      queryClient.invalidateQueries(['BoolmarkList']);
      showMessage('Bookmark deleted successfully', 'success');
    },
    ...options,
  });

  const deleteListBookmark = useMutation(deleteBookmarkList, {
    onSuccess: (_, _id) => {
      queryClient.invalidateQueries(['BoolmarkList']);
      showMessage('Bookmark deleted successfully', 'success');
    },
    ...options,
  });
  const ExportMultipleBookmarks = useMutation(downloadExportList, {
    ...options,
  });

  const update = useMutation(updateBookmark, {
    onSuccess: (_, _id) => {
      showMessage('Bookmark updated successfully', 'success');
      queryClient.invalidateQueries(['bookmark']);
    },
    ...options,
  });

  const newComment = useMutation(addComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(['bookmark']);
      showMessage('Comment added successfully', 'success');
    },
    onError: (error: any) => {
      showMessage(error?.response?.data?.message, 'error');
    },
    ...options,
  });
  return {
    create,
    deleteSingleBookmark,
    deleteListBookmark,
    update,
    ExportMultipleBookmarks,
    newComment,
  };
};
export default useMutateBookMark;
