import { IconButton, Tooltip } from '@demandscience/ui';
import BellIcon from '@demandscience/ui/icons/bell';

import DrawerControl from 'components/Notifications/DrawerControl';
import Badge from 'components/Notifications/Badge';

const NotificationsButton = ({ id }: { id?: string }) => (
  <Tooltip title="Notifications" position="right" showArrow>
    <Badge size="sm" theme="error">
      <DrawerControl
        id={id}
        data-testid="account-avatar"
        className="hover:bg-gray-200"
        as={IconButton}
        size="sm"
        // @ts-expect-error
        theme="default"
        aria-label="show notifications"
        Icon={BellIcon}
      />
    </Badge>
  </Tooltip>
);

export default NotificationsButton;
