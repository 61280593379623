import { memo, useState } from 'react';
import BookmarkAggregate from './BookmarkAggregate';
import LayersIcon from '@demandscience/ui/icons/layers';
import MapPinIcon from '@demandscience/ui/icons/map-pin';
import UsersIcon from '@demandscience/ui/icons/users';
import Building02Icon from '@demandscience/ui/icons/building-02';
import GridIcon from '@demandscience/ui/icons/grid';
import { CheckboxIcon } from '@demandscience/ui';
import { MessageSquare, AlertCircle } from '@demandscience/ui/icons';
import BookmarkInfo from './BookmarkInfo';
import { useBookmarkSelection } from './useBookmarkSelection';
import { aggregateJobFunctions } from './FilterBookmarkAggregate';
import DollarSignIcon from '@demandscience/ui/icons/dollar-sign';

interface BookMarkListProps {
  bookmark: any;
}

export const iconMap: { [key: string]: any } = {
  'Company location': MapPinIcon,
  Headcount: UsersIcon,
  Industry: Building02Icon,
  Department: GridIcon,
  'Job level': LayersIcon,
  Revenue: DollarSignIcon,
};

function BookMarkList({ bookmark }: BookMarkListProps) {
  const [open, setOpen] = useState(false);
  const { selectedBookmarks, toggleBookmark, updateName } = useBookmarkSelection();

  const label = bookmark?.module_type_name;
  const aggregate = aggregateJobFunctions(bookmark.search_results, bookmark.created_at);

  const handleCheckboxClick = () => {
    toggleBookmark(bookmark.id);
    updateName(bookmark.id, bookmark.name);
  };

  function isAnyDateLater(updated_at: string[], read_at: string) {
    const readDate = new Date(read_at);
    return updated_at.some((date) => new Date(date) > readDate);
  }

  return (
    <>
      <div className="flex flex-row items-center gap-2 mb-0.5 p-2 pl-0">
        <div onClick={handleCheckboxClick} data-testid="checkbox-icon">
          <CheckboxIcon size={18} checked={selectedBookmarks.has(bookmark.id)} />
        </div>

        <div style={{ color: '#64748B' }}>
          <AlertCircle data-testid="alert-circle-icon" onClick={() => setOpen(!open)} size={18} />
        </div>

        <div className="flex flex-row items-center gap-0.5" style={{ color: '#64748B' }}>
          {bookmark?.number_comments && (
            <>
              <MessageSquare size={18} />
              <div
                className="text-customGray"
                style={{ width: '8px', height: '16px', lineHeight: '16px' }}
              >
                {bookmark?.number_comments}
              </div>
            </>
          )}
          {aggregate &&
            bookmark?.updated_at &&
            bookmark?.read_at &&
            isAnyDateLater(bookmark?.updated_at, bookmark?.read_at) && (
              <div
                data-testid="bookmarkList-indicator"
                style={{
                  backgroundColor: '#F40356',
                  width: '5px',
                  height: '5px',
                  borderRadius: '50%',
                  marginLeft: '0.5rem',
                }}
              />
            )}
        </div>
      </div>

      <BookmarkInfo
        data-testid="bookmark-info-wrapper"
        data-state={open ? 'open' : 'closed'}
        isOpen={open}
        bookmark={bookmark}
      />

      <BookmarkAggregate
        data-testid="bookmark-aggregate"
        data-label={label}
        data-id={bookmark.id}
        id={bookmark.id}
        aggregate={aggregate}
        label={label}
        icon={iconMap[label]}
        loading={false}
        name={bookmark.name}
      />
    </>
  );
}

export default memo(BookMarkList);
