import useAuth from 'components/Auth/useAuth';
import BaseSidebar from 'layouts/Sidebar';

import includes from 'lodash/includes';
import filter from 'lodash/filter';

import SearchIcon from '@demandscience/ui/icons/search';
import ListIcon from '@demandscience/ui/icons/list-02';
import DashboardIcon from '@demandscience/ui/icons/dashboard';
import NotificationsButton from './NotificationsButton';
import AccountButton from './AccountButton';
import ZapIcon from '@demandscience/ui/icons/zap';

const navigationByRole = [
  { id: 'action_menu_search', label: 'Search', to: 'search', Icon: SearchIcon, role: 'user' },
  { id: 'action_menu_lists', label: 'Lists', to: 'lists', Icon: ListIcon, role: 'user' },
  {
    id: 'action_menu_manager',
    label: 'Manager',
    to: 'manage',
    Icon: DashboardIcon,
    role: 'manager',
  },
  {
    id: 'action_menu_buyerIntent',
    label: 'Buyer Intent',
    to: 'buyer-intent',
    Icon: ZapIcon,
    role: 'user',
  },
];

const Sidebar = (props: React.HTMLAttributes<HTMLElement>) => {
  const { user } = useAuth();
  const navigation = filter(navigationByRole, ({ role }) => includes(user?.roles, role));

  return (
    <BaseSidebar navigation={navigation} impersonated={user?.impersonated} {...props}>
      <NotificationsButton id="action_menu_notifications" />
      <AccountButton id="action_menu_user" />
    </BaseSidebar>
  );
};

export default Sidebar;
