import { Button, Modal } from '@demandscience/ui';
import { stopPropagation } from 'utils/event';
import { Link } from '@demandscience/ui';
import Alert from 'components/Alert';
import { HTMLAttributes } from 'react';

export interface BounseErrorPopupProps extends HTMLAttributes<HTMLDivElement> {
  closeModal: () => void;
  open: string | null;
  setApiError: React.Dispatch<React.SetStateAction<string | null>>;
}
const BounseErrorPopup = (props: BounseErrorPopupProps) => {
  const { open, closeModal, setApiError } = props;

  const onClose = () => {
    setApiError(null);
    closeModal();
  };
  return (
    <Modal
      aria-labelledby="create-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      open={!!open}
      onClose={onClose}
    >
      <Modal.Title
        id="create-list-dialog-title"
        className="flex flex-col w-[500px] h-[60px] p-[16px_24px_8px] items-start gap-[10px] mb-3"
      >
        Edit bounce file
      </Modal.Title>

      <Alert className="mb-4" message={'Your file contains formatting errors '} />

      <div className="text-sm text-gray-700 font-normal leading-5 font-inter">
        Your file cannot be uploaded as it contains formatting errors. Your file should contain
        email addresses in the first column and should not be larger than 10MB.
        <Link
          className="text-sm mt-2 text-blue-500 underline"
          href={`/download/bounce.xlsx`}
          onClick={stopPropagation}
          download
        >
          Download example
        </Link>
      </div>

      {open && <div className="text-error-500 text-center mt-2">{open}</div>}

      <div className="flex justify-end space-x-2 mt-8">
        <Button onClick={onClose} type="button">
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default BounseErrorPopup;
